.admin-panel-title {
    margin-bottom: 20px;
}

.card {
    height: 100%;
}

.card-body {
    text-align: center;
}

/* Add this CSS to your AdminPanel.css file */
.image-left .image {
    max-height: 100px; /* Adjust the value as needed */
  }
  