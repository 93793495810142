body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-success {
  /* background-color: #003122 !important; */

  background-color: #e76220 !important;
}

.btn-success {
  background: #e76220 !important;
  border: 1px solid #e76220;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #fd7e14; /* Orange */
  --bs-btn-border-color: #fd7e14;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc6805; /* Darker orange */
  --bs-btn-hover-border-color: #c95f04;
  --bs-btn-focus-shadow-rgb: 253, 126, 20;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #c95f04; /* Even darker orange */
  --bs-btn-active-border-color: #b35603;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #fd7e14;
  --bs-btn-disabled-border-color: #fd7e14;
}
