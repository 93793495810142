body {
    background-color: #ffffff;
    font-family: 'Arial', sans-serif;
}

/* .bg-success {
  background: #003122 !important;
} */
.text-primary {
    color: #4CAF50 !important;
}

.jumbotron {
    /* background: #003122; */
    background: #e76220;
    background: #e76220 url('../../../assets/images/bg-hero-section.jpg') no-repeat center center;
    /* background: #003122 url('../../../assets/images/bg-hero-section.jpg') no-repeat center center; */
    /* background: #4CAF50 url('https://nff.org.au/wp-content/uploads/2020/01/back-cover-graphic-1500x500.png') no-repeat center center; */
    /* background: #4CAF50 url('https://png.pngtree.com/thumb_back/fh260/back_our/20190620/ourmid/pngtree-delicious-food-small-fresh-minimalist-green-food-poster-background-image_151576.jpg') no-repeat center center; */
    /* background: #4CAF50 url('https://img.freepik.com/premium-photo/set-fragrant-spices-mixture-black-red-pepper-coriander-paprika-wooden-background-top-view-free-space_187166-38523.jpg') no-repeat center center; */
    
    /* background: #fcfcfc; */
    
    background-size: cover;
    border-bottom: 2px solid #CCCCCC;

    /* color:#333333 !important; */
}
.jumbotron img{
    background: #ffffff;
    padding: 10px;
    border-radius: 10px;
    position: relative;
    z-index: 2;
}
/* Left fade effect */
.jumbotron::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent);
    pointer-events: none;
    transition: opacity 0.5s ease;
    opacity: 0;
}

/* Right fade effect */
.jumbotron::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.5), transparent);
    pointer-events: none;
    transition: opacity 0.5s ease;
    opacity: 0;
}

/* Hover effect for left fade */
.jumbotron:hover::before {
    opacity: 1;
}

/* Hover effect for right fade */
.jumbotron:hover::after {
    opacity: 1;
}
.jumbotron .display-4 {
    font-weight: 700;
}

.jumbotron .lead {
    font-size: 1.25rem;
    color:'inherit';
    margin-top: 1em;
    font-style: italic;
}

.value-card {
    padding: 40px;
    border-radius: 10px;
}

.primary-value {
    /* background-color: #4CAF50; */
    background-color: #003122;
}

.secondary-value {
    background-color: #FFA500;
}

footer {
    /* background: #4CAF50; */
    background: #e76220;
}

.card-title {
    font-weight: 600;
}

.card-text {
    font-size: 1rem;
}

.img-fluid {
    margin-bottom: 1rem;
}