/* Custom colors to override Bootstrap default colors */

:root {
  /* Define custom color variables */
  --primary-color: #007bff;
  /* Blue */
  --secondary-color: #6c757d;
  /* Gray */
  /* --success-color: #007933; */
  --success-color:#8bbf2d;
  /* Green */
  --info-color: #17a2b8;
  /* Cyan */
  --warning-color: #ffc107;
  /* Yellow */
  --danger-color: #dc3545;
  /* Red */
  --light-color: #f8f9fa;
  /* Light gray */
  --dark-color: #343a40;
  /* Dark gray */
}

body {
  background-color: #efefef;
}

/* .bg-success {
  background: #003122 !important;
} */

.card {
  /* background-color: var(--light-color);  */
  background-color: var(--light-color);
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

}

.card:focus,
.card:hover {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}

/* Add additional shadow styling for large cards */
.card-lg {
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
}

/* Override default button colors */
.btn-primary {
  background-color: var(--primary-color);
  /* Use custom primary color for button background */
  border-color: var(--primary-color);
  /* Use custom primary color for button border */
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: #0056b3;
  /* Darken primary color on hover/focus */
  border-color: #0056b3;
  /* Darken primary color on hover/focus */
}

.btn-secondary {
  background-color: var(--secondary-color);
  /* Use custom secondary color for button background */
  border-color: var(--secondary-color);
  /* Use custom secondary color for button border */
}